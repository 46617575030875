import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleAuthenticationApiService } from '@accredible-frontend-v2/services/authentication';
import { AccredibleUserApiService } from '@accredible-frontend-v2/services/user';
import { accredibleCustomThemesMetadata } from '@accredible-frontend-v2/utils/themes';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  createNgModuleRef,
  Inject,
  Injector,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, skip } from 'rxjs/operators';
import { ThemeHelper } from '../themes/theme.helper';

@UntilDestroy()
@Component({
  selector: 'acs-root',
  templateUrl: './accounts.container.html',
  styleUrls: [`./accounts.container.scss`],
})
export class AccountsContainer implements OnInit, AfterViewInit {
  @ViewChild('headerContainer', { read: ViewContainerRef })
  headerContainer: ViewContainerRef;

  showHeader = true;
  readonly version = environment.version;

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _injector: Injector,
    private readonly _router: Router,
    private readonly _authenticationApi: AccredibleAuthenticationApiService,
    private readonly _userApi: AccredibleUserApiService,
  ) {}

  ngOnInit(): void {
    this._calculateShowHeader();

    this._router.events
      .pipe(
        untilDestroyed(this),
        skip(1),
        filter((event: Event | RouterEvent) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this._authenticationApi.checkLocalSession();
        this._calculateShowHeader();
      });
  }

  ngAfterViewInit(): void {
    this._loadHeaderComponent().then();
  }

  private _calculateShowHeader(): void {
    // Hide the header if user is signed out
    this.showHeader = this._userApi.isUserSignedIn();

    // or if user is on one the following routes
    const hideHeaderRoutes = [
      '/email-verification',
      '/reset-password',
      '/transcripts-terms-and-conditions',
    ];
    hideHeaderRoutes.forEach((route) => {
      if (this._document.location.href.includes(route)) {
        this.showHeader = false;
      }
    });
  }

  private async _loadHeaderComponent(): Promise<void> {
    const theme = ThemeHelper.getTheme();
    // If this theme is a duplicate, reference the domain of the original theme in order to get the header component
    const themeDomain = accredibleCustomThemesMetadata[theme]?.duplicateOf || theme;

    // We use a try catch here so the app will attempt to load the header component for this._theme but if it does not exist, e.g. if it's not been built. We load the default header component instead.
    try {
      const { HeaderComponent } = await import(`../themes/${themeDomain}/header/header.component`);
      import(`../themes/${themeDomain}/header/header.component.module`)
        .then((m) => m.HeaderComponentModule)
        .then(async (module) => {
          await this._createComponent(module, HeaderComponent);
        });
    } catch {
      const { HeaderComponent } = await import(
        '../themes/default.accounts.theme/header/header.component'
      );
      import('../themes/default.accounts.theme/header/header.component.module')
        .then((m) => m.HeaderComponentModule)
        .then(async (module) => {
          await this._createComponent(module, HeaderComponent);
        });
    }
  }

  private async _createComponent(module: any, component: any): Promise<any> {
    const moduleRef = createNgModuleRef(module, this._injector);
    this.headerContainer.clear();
    this.headerContainer.createComponent(component, { ngModuleRef: moduleRef });
  }
}
